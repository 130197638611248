/** @jsx jsx */
import * as React from "react"
import { useColorMode, jsx, Button } from 'theme-ui'
import { motion } from "framer-motion"
import { FiMoon, FiSun } from "react-icons/fi"

export const TheSwitcher = ({ props }) => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
	const toggleColorMode = e => {
		setColorMode(isDark ? `light` : `dark`)
	}
  const MotionButton = motion(Button)
  return (
    <div>
      <MotionButton
      variant="themeswitch"
			{...props}
			onClick={toggleColorMode}
			aria-label="Toggle dark mode"
			whileHover={{ scale: 1.15 }}
			whileTap={{ scale: 0.95 }}
      >
      {isDark ? (
        <FiMoon size="1.2em" />
    ) : (
        <FiSun
            size="1.2em"
            sx={{
                color: "var(--theme-ui-colors-navlinks)",
                fill: "var(--theme-ui-colors-navlinks)",
            }}
        />
    )}
      </MotionButton>
    </div>
  )
}
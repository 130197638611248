import React from "react"
import { Global } from "@emotion/react"

export default props => (
	<Global
		styles={`
    :root {
        --heading-height: 6rem;
        // --heading-height: 8.2rem;
        --global-border-width: 12px;
        @media (min-width: 540px) {
          --global-border-width: 24px;
          --heading-height: 8.2rem;
          // --heading-height: 96px;
        }
    }
    :focus {       
        outline-color: var(--theme-ui-colors-muted);
        outline-style: none;
        outline-width: 0;
    }
    ::selection { 
      background: var(--theme-ui-colors-selection); 
    }
`}
/>
)
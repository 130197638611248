/** @jsx jsx */
import { jsx, Box } from "theme-ui"

export const TheFooter = ({ props }) => (
	<footer
		sx={{
			p: 3,
			zIndex: 1,
			variant: "layout.footer",
            bg: "muted"
		}}
	>
		<div
			sx={{
				display: "grid",
				gridTemplateColumns: "repeat(auto-fit, minmax(256px, 1fr))",
				alignItems: "center",
				// maxWidth: 768,
				mx: "auto",
			}}
		>
			<Box p={2} sx={{ textAlign: ["center", "center", "left"] }}>
				v.02
			</Box>
			<Box p={2} sx={{ textAlign: ["center", "center", "right"] }}>
				© {new Date().getFullYear()} smartassdesign
			</Box>
		</div>
	</footer>
)

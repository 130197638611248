/** @jsx jsx */
import { jsx } from "theme-ui"

export const LogoLockup = ({ w, ...props }) => {
	//   const w = w || "227.72"
	const h = w / 0.3094189902
	return (
		<svg
			x="0px"
			y="0px"
			className="logosvg"
			width={`${w || "227.72"}px`}
			height={`${h}px`}
			{...props}
			viewBox="0 0 227.72 735.96"
			sx={{
				fill: "navlinks",
			}}
		>
			<path
				d="M0,403.05c0-0.26,0-0.51,0-0.77c1.09-2.67,1.79-6,2.32-9.03c1.1-6.24,2.81-12.1,4.38-17.79
    c0.91-3.29,0.72-6.54,1.29-10.06c0.49-3.04,1.62-6.19,2.58-9.28c0.96-3.09,1.87-6.28,2.58-9.54c1.4-6.44,2.9-13.1,4.13-19.86
    c3.03-16.75,4.81-35.87,10.57-49.51c1.75-4.13,4.2-8.66,5.67-12.38c1.83-4.63,2.33-9.34,3.09-14.96c0.93-6.78,2.19-14.05,2.58-21.4
    c0.2-3.87,0.12-7.85,0.26-11.86c0.14-3.93,0.55-7.88,0.26-11.86c-0.61-8.33-3.3-17.03-3.35-26.3c-0.02-4.38,0.42-9.29,1.03-13.93
    c1.43-10.81,3.76-15,10.57-20.63c1.38-1.14,3.14-2.03,4.64-3.09c1.83-1.29,3.07-2.68,4.64-3.35c2.13-0.91,4.67-1.08,6.96-1.8
    c6-1.9,11.34-3.31,17.53-5.67c7.09-2.7,17.34-6.67,19.34-11.86c0.93-2.4,0.83-5.74,1.03-8.77c0.63-9.62,1.36-18.45,0-27.85
    c-3.08,0.33-7.55,0.09-9.28-2.06c-2.88-3.58-0.69-15.36,0.77-18.31c0.86-1.73,3.51-2.97,3.87-4.64c0.18-0.85-0.16-2.46-0.26-3.61
    c-0.38-4.47-1.28-9.12-1.03-12.89c0.14-2.12,0.74-5.28,1.55-7.74c0.37-1.13,0.98-2.1,1.29-3.09c0.4-1.29,0.5-2.35,0.77-3.61
    c0.39-1.81,2.89-3.25,3.87-4.9c0.56-0.94,0.8-1.98,1.29-2.84c0.53-0.92,0.96-1.93,1.55-2.58c2.54-2.8,6.53-3.42,9.03-6.7
    c1.61-2.13,2.2-5.59,4.13-6.96c1.38,0.08,2.24,2.24,3.61,0.77c2.27-0.49,4.37-1.89,6.96-2.06c1.75-0.12,3.51,0.55,5.16-0.26
    c1.08,0.07,1.51,0.91,2.84,1.03c1.29,0.12,3.11-0.45,4.64-0.52c1.52-0.06,2.92-0.05,3.87,0c0.76,0.66,1.44,2.04,0.78,3.35
    c0.34,1.3,2,1.26,2.06,2.84c0.88,1.07,1.91,0.48,3.61,0.52c0.86,2.39,3.35,2.83,5.42,3.87c2.27,1.14,3.04,2.79,4.38,4.9
    c-0.53,1.61-1.22,3.52-1.03,5.16c0.16,1.35,1.38,2.99,1.81,4.64c0.5,1.95,0.56,4,1.03,5.67c0.61,2.13,2.16,4.09,2.32,6.19
    c0.09,1.1-0.33,2.49-0.26,3.87c0.06,1.31,0.36,2.59,0.26,3.61c-0.16,1.52-0.93,2.8-1.29,4.38c-1.14,5-2,8.99-3.35,13.93
    c0.25,1.05,1.36,0.92,2.06,1.55c3.51,3.16,1.94,10.29,1.03,15.21c-0.63,3.4-1.5,5.89-2.84,6.7c-2.26,1.38-4.82,0.71-7.48,0.77
    c-1.96,4.93-1.8,10.6-1.8,17.79c0,3.1-0.68,7.23,0,9.54c0.46,1.59,4.27,4.18,7.22,5.67c2.99,1.51,6.64,2.87,9.03,3.87
    c5.24,2.2,9.21,4.06,13.93,5.42c1.69,0.48,3.35,0.52,5.41,1.03c2.55,0.63,6.09,2.26,9.28,3.87c1.39,0.7,2.69,1.7,4.13,2.58
    c1.5,0.92,3.29,1.49,4.38,2.32c1.76,1.33,3.47,3.29,4.38,4.64c2.65,3.92,3.28,10.21,4.12,15.73c0.69,4.5,0.89,9.2,0.77,14.18
    c-0.21,8.88-2.69,17.66-2.06,27.08c0.62,9.23,0.84,18.92,1.29,28.37c0.45,9.34,0.1,18.73,2.32,27.08c1.02,3.81,2.9,7.11,3.87,10.83
    c1.08,4.14,1.43,8.62,1.8,13.15c0.76,9.18,2.04,18.19,2.06,27.85c0.03,9.38-0.24,18.63-0.52,27.59c-0.21,7.04-0.59,13.93,0,20.37
    c0.2,2.17,0.77,4.53,0.77,6.7c0,3.05-0.82,6.32-0.77,9.54c0.04,3.31,0.62,6.55,0.77,9.8c0.15,3.25,0.14,6.62,0.52,9.8
    c0.37,3.13,1.76,6.21,1.55,9.02c-0.18,2.35-1.84,4.31-2.58,6.71c-0.65,2.11-1.05,4.87-1.8,7.22c-0.71,2.22-1.23,5.6-2.32,6.96
    c-0.96,1.2-4.01,2.17-6.19,3.35c-1.89,1.03-4.65,2.79-6.45,3.09c-3.84,0.66-6.7,0.34-10.06-0.52c-0.18-2.58,0.41-4.41,0.77-6.45
    c1.3-1.02,3.22-1.42,5.42-1.55c1.86-2.35,5.64-2.78,7.48-5.16c0.13-1.68,0-2.99,0.26-4.64c0.24-1.49,1.01-2.97,1.03-4.38
    c0.01-1.13-0.62-2.25-0.77-3.35c-0.19-1.33,0.39-2.71-0.52-3.87c-1.21-1.12-4.47-1.26-6.19-1.03c-2.01,6.85-1.08,17.18-9.8,17.02
    c-1.64-1-1.5-2.67-1.55-4.38c-0.13-5.17,1.99-9.28,2.32-13.67c0.26-3.37-0.16-6.81,0.26-10.57c0.39-3.46,1.87-6.7,3.35-9.28
    c1.54-2.69,4.07-4.66,5.16-7.48c1.35-3.5,1.53-8.31,1.29-12.38c-0.74-12.71-5.78-24.37-8.25-34.55c-1.7-7-3.3-15.75-4.38-23.98
    c-1.09-8.28-4.27-16.99-3.61-25.01c0.15-1.82,0.88-3.63,1.03-5.42c0.27-3.09-0.19-6.73-0.26-10.06c-0.07-3.44,0.05-7.03-0.26-10.31
    c-0.39-4.2-1.89-8.64-2.84-12.89c-0.99-4.44-2.63-8.69-3.09-12.89c-1.15,0.41-1.07,1.93-1.29,2.84c-1.97,8.14-3.27,15.95-5.42,23.47
    c-1.23,4.31-2.03,8.87-3.09,13.41c-1.06,4.51-2.26,9.2-2.84,13.93c-1.17,9.55,2.47,19.31,3.61,29.14c1.16,9.95,3.7,19.84,3.61,29.65
    c-0.01,1.57-0.58,3.09-0.52,4.64c0.06,1.44,0.59,3.25,0.77,5.16c0.19,1.96-0.16,4.5,0.26,5.93c0.26,0.89,1.21,1.5,2.06,2.32
    c5.46,5.26,5.73,15.21,6.19,25.01c0.41,8.81,1.18,17.36,1.55,25.01c0.74,15.47,1.61,31.61-1.29,44.09
    c-0.41,1.76-1.32,3.36-1.55,5.16c-0.29,2.34,0.71,5.16,0.26,7.48c-0.32,1.62-2.08,3.02-3.09,4.64c-1.57-0.1-2.41,0.67-3.87,1.03
    c-1.54,0.38-2.96,0.07-3.87,1.03c-0.55,0.59-1.02,3.98-1.55,6.96c-0.49,2.79-0.72,5.65-1.03,7.74c-2.36,15.81-8.33,28.27-6.45,45.64
    c0.16,1.52,0.61,2.97,0.77,4.38c0.96,8.29,0.28,17.82,1.03,25.27c0.91,9.03,3.9,18.32,3.09,28.62c-0.47,6.08-1.87,12.38-3.09,18.05
    c-1.24,5.75-2.44,11.67-3.61,17.54c-1.16,5.83-2.84,11.41-4.13,17.02c-2.54,11.08-8.68,21.3-9.28,33.01
    c-0.21,3.96,1.33,7.46,1.29,10.83c-0.04,3.93-2.15,6.32-1.8,9.8c0.16,1.58,1.29,2.72,2.06,4.38c0.71,1.51,1.2,3.22,1.8,4.64
    c1.35,3.15,3.06,5.93,4.9,7.99c1.62,1.82,4.01,2.9,5.42,4.64c0.77,0.96,1.54,2.63,2.58,4.13c1.05,1.52,2.07,3.13,2.32,4.38
    c0.59,2.98-0.22,5.76-1.03,7.74c-0.34,0.83-0.38,1.8-0.77,2.32c-0.55,0.74-4.38,3.09-5.16,3.35c-1.03,0.35-2.53,0.16-3.87,0.52
    c-1.95,0.52-2.57,1.04-4.12,1.03c-2.43-0.01-3.52-1.27-5.16-1.29c-0.76-0.01-1.75,0.6-2.58,0.77c-4.11,0.86-9.34-0.17-11.09-2.58
    c0.18-3.9-2.44-5.7-3.1-8.51c-0.87-3.72-0.51-7.78-1.29-11.6c-0.62-3.04-2.54-4.8-3.35-7.48c-0.79-2.6-0.38-4.92-0.52-7.99
    c-0.07-1.56-0.48-3.22-0.52-4.9c-0.04-1.85,0.13-3.75,0-5.67c-0.13-1.95-1.16-4.26-1.03-6.45c0.12-1.97,1.26-4.17,2.06-6.19
    c1.19-2.97,2.64-5.51,3.61-9.03c0.86-3.14,1.63-7.4,1.55-10.83c-0.04-1.47-0.73-3.43-0.77-4.9c-0.04-1.46,0.46-2.93,0.52-4.38
    c0.21-5.43-1.18-9.79-1.55-14.7c-0.14-1.89,0.38-3.78,0.26-5.67c-0.15-2.3-0.86-4.61-1.03-6.96c-0.19-2.5-0.33-4.98-0.52-7.48
    c-0.65-8.69-0.39-16.84,0.52-24.24c0.7-5.71,1.9-10.97,2.84-16.25c0.52-2.94,1.26-6.2,1.03-8.51c-0.17-1.74-1.27-3.08-2.06-4.9
    c-0.73-1.66-1.53-3.49-2.06-5.16c-0.81-2.52-1.04-5.18-1.8-7.74c-0.77-2.55-1.58-4.88-2.06-7.48c-0.56-3.02-0.65-5.73-0.77-8.77
    c-0.12-2.88,0.37-5.81,0.26-9.03c-0.06-1.85-0.47-3.78-0.52-5.67c-0.05-2.05-0.29-3.92-0.52-5.93c-1.37-12.13,0.45-24.44-2.58-35.33
    c-2.45-1.84-5.85-2.75-6.71-6.19c-0.26-2.03,0.77-3.51,0.52-5.42c-0.13-0.96-1.01-1.68-1.29-2.58c-1.48-4.73,0.96-9.35-0.26-14.96
    c-1.28-0.7-2.17,0.93-3.1,1.55c-1.08,0.73-2.44,1.04-3.09,2.06c1.34,4.38-1.99,8.26-2.58,11.86c-0.34,2.09,0.6,4.02,0,5.93
    c-0.33,1.05-0.98,0.95-1.55,1.55c-2.47,2.62-2.28,13.78-2.06,18.57c0.67,14.83,1.33,27.74,0.26,41.52
    c-0.23,2.92-0.03,6.02-0.52,8.51c-0.33,1.72-1.4,3.42-2.06,5.41c-1.3,3.93-2.1,7.74-3.35,11.6c-0.31,0.96-0.61,1.71-1.03,2.58
    c-1.17,2.45-1.98,5.22-2.06,8.77c-0.04,1.87,0.49,3.44,0.77,5.42c1.07,7.52,1.7,14.41,2.06,22.18c0.18,3.9,0.52,8.18,0.26,11.6
    c-0.06,0.74-0.64,1.58-0.77,2.32c-0.18,1.02-0.17,2.11-0.26,3.09c-1.05,11.47-1.18,24.72-2.06,35.59c-0.16,1.93-0.67,3.82-0.77,5.67
    c-0.46,8.13,0.7,15.56,2.84,20.89c1.2,3.01,3.29,5.52,3.61,8.77c0.29,2.93-0.92,6.01-1.29,9.54c-0.33,3.15-0.92,6.37-1.03,9.28
    c-0.1,2.77,0.48,5.27,0,6.96c-0.62,2.17-3.42,3.25-4.64,5.93c-0.37,0.8-0.6,2.12-1.03,3.35c-0.85,2.45-1.2,4.91-1.81,7.74
    c-0.82,3.86-1.73,5.94-4.38,7.48c-1.19,1.96-1.03,3.18-2.32,4.38c-2.51,2.35-6.75,2.47-9.8,1.29c-0.98-0.38-1.94-1.25-3.09-1.29
    c-0.8-0.03-1.75,0.6-2.58,0.77c-2.01,0.42-3.86,0.43-5.16-0.26c-0.33-0.18-0.69-1.02-1.29-1.29c-1.47-0.67-3.19-0.45-4.13-1.03
    c-1.35-0.84-1.82-2.94-3.09-3.09c-0.96-2.93-3.01-3.87-2.58-7.74c0.36-3.23,4.22-6.59,6.19-8.77c0.93-1.02,1.52-2.29,2.32-2.84
    c0.89-0.62,2.09-0.59,2.84-1.03c0.76-0.45,1.4-1.2,2.06-1.8c1.2-1.12,2.7-2.45,3.87-4.13c1.32-1.89,2.1-4.26,3.35-6.45
    c1.16-2.02,3.45-4.22,3.61-6.19c0.2-2.37-1.25-5.23-1.29-7.74c-0.06-3.3,1.47-6.36,1.55-10.31c0.15-8.06-2.64-15.96-4.64-21.92
    c-2.63-7.83-5.13-15.74-6.96-24.24c-0.62-2.86-0.85-5.8-1.55-8.77c-0.65-2.79-1.36-5.63-1.81-8.51c-0.92-5.95-2.2-11.9-2.84-18.05
    c-1.01-9.79-0.24-19.34,1.03-28.37c0.42-2.94,1.1-5.95,1.55-9.03c0.93-6.33,0.22-13.39,0.52-20.37c0.28-6.7,1.22-13.52,0.77-19.6
    c-0.15-2.03-0.96-4.13-1.29-6.19c-0.35-2.17-0.68-4.34-1.03-6.45c-2.12-12.87-5.35-25.56-7.74-37.39c-6.16-1.15-12-2.61-16.25-5.67
    c-0.12-1.76,0.13-3.14,0.26-4.64c-1.96-2.29-1.82-5.63-2.06-8.51c-0.86-10.19-1.07-20.06,0-30.17c0.68-6.43,0.88-12.99,1.81-19.34
    c0.44-3.04,1.06-6.02,1.55-9.03c0.51-3.15,0.64-6.38,1.29-9.28c1.36-6.13,4.1-13.38,5.42-19.6c0.53-2.52,0.47-5.3,1.29-7.22
    c0.99-2.33,3.65-3.55,5.67-5.16c0.09-2.04,0.37-3.91,0.77-5.93c0.35-1.75,1.21-3.45,1.55-5.16c0.46-2.31,0.46-4.62,0.77-6.7
    c0.34-2.25,0.72-4.31,1.29-6.45c1.01-3.82,2.67-7.1,4.38-11.09c1.66-3.86,2-7.97,3.09-12.12c1.01-3.83,2.73-7.66,4.13-11.35
    c3.21-8.47,3.64-16.77,2.58-26.56c-0.76-7.01-1.27-14.32-1.55-21.66c-0.08-2.15-0.01-4.66-1.03-6.71
    c-1.75,8.3-3.76,17.03-5.42,25.27c-1.06,5.3-2.31,10.16-1.81,16.76c0.21,2.78,1.23,5.45,1.29,7.99c0.17,7.48-1.94,12.24-4.13,17.79
    c-4.06,10.31-8.09,21.64-12.38,31.2c-2.91,6.5-7.07,12.4-10.06,18.82c-2.36,5.06-4.23,10.27-6.7,14.96
    c-1.5,2.83-3.31,6.11-3.61,9.54c-0.38,4.41,1.4,7.19,2.84,10.06c0.85,1.7,1.87,3.34,2.32,5.42c0.94,4.34-0.73,8.29-0.52,12.89
    c0.17,3.74,5.57,16.61,0.26,18.57c-1.81,0.66-3.97-0.59-5.16-2.06c-2.68-3.31-3.19-10.3-4.13-14.7c-2.69-0.2-4.78,0.2-6.45,1.03
    c-0.05,3.43-0.95,5.76-0.77,7.74c0.09,0.94,0.87,3.72,1.29,4.64c0.62,1.36,2.18,2.3,3.35,3.61c0.33,0.37,0.29,0.77,0.52,1.03
    c0.85,0.98,2.2,1.52,3.09,2.32c0.97,0.87,2.03,2.37,3.61,3.35c0.99,0.62,1.71,0.59,2.32,1.29c3.25,3.75-1.86,6.2-4.38,7.74
    c-1.86-1.4-4.24-1.43-6.19-2.32c-2.29-1.05-4.22-2.96-6.19-4.38c-2.16-1.57-4.49-2.88-5.67-5.16c-1.11-4.68-1.96-9.98-3.61-14.18
    C0.8,406.3,0.1,404.28,0,403.05z"
			/>
		</svg>
	)
}

/** @jsx jsx */
import { Link as GatsbyLink } from "gatsby"
import { jsx, Flex } from "theme-ui"
import { LogoLockup } from "../components/LogoLockup"
import { TheLink } from "../components/TheLink"
import { TheSwitcher } from "../components/TheSwitcher"
import { motion } from "framer-motion"
import { useMedia } from "react-use"

const menuLinks = [
    // {
    //     label: "Work",
    //     link: "/work/",
    // },
    {
        label: "About",
        link: "/about/",
    },
    {
        label: "Contact",
        link: "/contact/",
    },
]

const ListLink = props => (
	<GatsbyLink
		to={props.to}
		sx={{
			variant: "links.nav"
		}}
		getProps={({ isPartiallyCurrent }) =>
			isPartiallyCurrent ? { "data-active": "true" } : null
		}
	>
		{props.children}
	</GatsbyLink>
)

const duration = 0.5

const variants = {
	intitial: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
		transition: {
			duration: duration,
			delay: duration,
			when: "beforeChildren",
		},
	},
	exit: {
		opacity: 0,
		transition: { duration: duration },
	},
}

export const TheHeader = ({ siteTitle }) => {
	

	const isWide = useMedia("(min-width: 992px)")

	return (
		<motion.header
			sx={{
				variant: `layout.header.default`,
				height: "var(--heading-height)",
			}}
			data-headertype="transparent"
			key="default"
			variants={variants}
			initial="initial"
			animate="enter"
			exit="exit"
		>
			<TheLink
				to="/"
				aria-label="Home Page"
				sx={{ color: "heading", px: 3 }}
			>
				<motion.div
					whileHover={{ scale: 1.065 }}
					whileTap={{ scale: 0.97 }}
					transition={{ duration: 0.365 }}
				>
					<LogoLockup w={isWide ? "24" : "20"} />

					<span className="sr-only" sx={{ display: "none" }}>
						{siteTitle}
					</span>
				</motion.div>
			</TheLink>

			<Flex
				className="headerlinks"
				sx={{
					alignItems: "center",
					variant: "styles.Navlinks",
					position: "relative",
					display: "flex",
					"@media screen and (max-width: 992px)": {
						display: "none",
					},
				}}
			>
				{menuLinks.map((item, i) => (
					<ListLink key={i} to={item.link}>
						{item.label}
					</ListLink>
				))}

				<TheSwitcher />
			</Flex>
		</motion.header>
	)
}

/** @jsx jsx */
import * as React from "react"
import { jsx, Box, Container, Heading } from "theme-ui"
import { TheHeader } from "./TheHeader"
import Global from "../styles/globals"
import { TheFooter } from "./TheFooter"

export const TheLayout = ({children, ...props}) => (
    <>
    <Global />
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
      <TheHeader />
      <main
        sx={{
            variant: `layout.main.${props.centered ? 'centered' : 'default'}`,
        //   bg: "pink"
        }}>
        {children}
      </main>
      <TheFooter />
    </div>
    </>
  )